<template>
  <div class="relative">
    <div class="inline-flex items-center justify-center quantity-select-action w-full">
      <label
        v-if="hasDefaultSlot"
        :for="getInputId"
        class="lg:inline-block lg:align-middle label uppercase text-black text-h6 tracking-md leading-58px whitespace-no-wrap border border-solid px-xs text-center border-dark h-14 w-16 px-2 min-w-5 border-r-0"
      >
        <slot />
      </label>
      <input
        :id="getInputId"
        type="number"
        :min="min"
        class="text-dark py-sm px-5 focus:outline-none focus:bg-grey-11 border border-solid border-dark text-average leading-58px tracking-xs w-full h-14"
        :focus="autofocus"
        :value="value"
        :readonly="readonly"
        @input="inputEvent"
        @blur="blurEvent"
        @focus="$emit('focus')"
        @keyup.enter="keyupEnter"
        @keyup.up="incrementEvent"
        @keyup.down="decrementEvent"
        @keyup="$emit('keyup', $event)"
      >
      <button @click.prevent="decrementEvent" class="lg:inline-block lg:align-middle border border-black border-solid m-0 bg-transparent text-grey-17 hover:text-black hover:bg-grey-11 focus:outline-none border-l-0 h-14 w-14 min-w-4 leading-58px text-center text-2h6">
        <i class="ss-gizmo ss-hyphen inline-block mt-px" />
      </button>
      <button @click.prevent="incrementEvent" class="lg:inline-block lg:align-middle border border-black border-solid m-0 bg-transparent text-grey-17 hover:text-black hover:bg-grey-11 focus:outline-none border-l-0 h-14 w-14 min-w-4 leading-58px text-center text-2h6">
        <i class="ss-gizmo ss-plus inline-block mt-px" />
      </button>
    </div>
    <ValidationMessages v-if="validations" :validations="validations" />
  </div>
</template>

<script>
import ValidationMessages from '../Form/ValidationMessages'
import debounce from 'lodash-es/debounce'

export default {
  name: 'ProductBaseInputNumber',
  components: {
    ValidationMessages
  },
  data () {
    return {
    }
  },
  props: {
    value: {
      type: [String, Number],
      default: 1
    },
    name: {
      type: String,
      required: false,
      default: ''
    },
    min: {
      type: Number,
      default: 1
    },
    increment: {
      type: Number,
      default: 1
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        const value = this.round(this.value)
        this.$emit('input', value)
      }
    }
  },
  beforeMount() {
    this.$emit('input', this.increment)
  },
  computed: {
    getInputId () {
      return `input_${this._uid}`
    },
    hasDefaultSlot () {
      return !!this.$slots.default
    }
  },
  methods: {
    keyupEnter ($event) {
      let value = parseInt($event.target.value)

      if (isNaN(value)) {
        value = this.increment
      }
      this.$emit('keyup.enter', value)
    },
    inputEvent: debounce(function ($event) {
      let value = parseInt($event.target.value, 10)

      if (isNaN(value)) {
        value = this.increment
      }

      this.$emit('input', value)
    }, 500),
    blurEvent ($event) {
      let value = parseInt($event.target.value, 10)

      if (isNaN(value)) {
        value = this.increment
      }
      this.$emit('blur', value)
    },
    incrementEvent ($event) {
      let value = this.value;
      value += this.increment;
      this.$emit('input', value)
    },
    decrementEvent ($event) {
      let value = this.value;
      value -= this.increment;
      this.$emit('input', value)
    },
    round (val) {
      val = parseInt(val, 10)
      if (val < this.increment) {
        return this.increment
      }
      return Math.round(val / this.increment) * this.increment
    }
  }
}
</script>

<style lang="scss" scoped>
  .quantity-select {
    max-width: inherit;
  }

  input[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
</style>

<template>
  <form v-if="isEnabled" class="back-in-stock-form" @submit="onSubmit">
    <base-input
      v-model="email"
      type="email"
      name="email"
      class="input"
      :placeholder="$t('Email *')"
      :validations="[
          {
            condition: !$v.email.required && $v.email.$error,
            text: $t('Field is required.')
          },
          {
            condition: !$v.email.email && $v.email.$error,
            text: $t('Please provide valid e-mail address.')
          }
        ]"
      @blur="$v.email.$touch()"
    />
    <button-with-icon
      type="submit"
      color="primary"
      :disabled="$v.$invalid"
      @click.native="$v.email.$touch"
      class="mt-4"
    >
      <span>
        {{ $t('Notify me when available') }}
      </span>
    </button-with-icon>
  </form>
</template>

<script>
import config from 'config'
import rootStore from '@vue-storefront/core/store'
import BaseInput from 'theme/components/core/blocks/Form/BaseInput'
import BaseCheckbox from 'theme/components/core/blocks/Form/BaseCheckbox'
import ButtonWithIcon from 'theme/components/theme/ButtonWithIcon'
import { required, email } from 'vuelidate/lib/validators'
import fetch from 'isomorphic-fetch'
import gql from "graphql-tag";

export default {
  name: 'BackInStockForm',
  components: {
    BaseInput,
    BaseCheckbox,
    ButtonWithIcon
  },
  props: {
    product: {
      type: Object,
      required: true
    },
    newsletter: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      email: null
    }
  },
  mounted () {
    this.email = rootStore.state.user.current.email
  },
  computed: {
    isEnabled () {
      return !!config?.magento?.url && !!config?.magento?.outOfStockNotify
    }
  },
  methods: {
    onSubmit(e) {
      e.preventDefault()
      fetch(config?.magento?.url + '/graphql', {
        method: 'POST',
        credentials: 'omit',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authentication': 'Bearer ' + rootStore.state.user.token
        },
        body: JSON.stringify({
          query: `
            mutation {
              AmxnotifStockSubscribe(input: {
                gdpr_agreement: true
                product_id: ${this.product.id},
                email: "${this.email}",
              }) {
                response_message
              }
            }
          `.replace(/\s+/g, ' ').trim()
        }),
      }).then((res) => res.json())
        .then((json) => {
          if (!json.errors) {
            this.$emit('success', json)
          } else {
            this.$emit('error', json)
          }
        });
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped>
.back-in-stock-form {
  &::v-deep {
    .input {
      @apply text-left;
    }
  }
}
</style>

// Config example
//
// "products": {
//   "imageScalingByAttributeSetId": {
//     "11": {
//       "label": "Hair Care Bottles",
//         "height": 320
//     },
//     "16": {
//       "label": "Hair Care Tubs & Sticks",
//         "height": 200
//     }
//   }
// }

import config from 'config'

export default {
  name: 'ProductImageScaling',
  computed: {
    scalingByAttributeSet () {
      return config.products.gallery.imageScalingByAttributeSetId
    }
  },
  methods: {
    getProductImageHeight (product) {
      let scalingConfig = this.scalingByAttributeSet
      let productAttributeSetId = product.attribute_set_id
      let imageHeight = 320

      if (scalingConfig.hasOwnProperty(productAttributeSetId)) imageHeight = scalingConfig[productAttributeSetId].height

      return imageHeight
    }
  }
}

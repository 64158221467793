var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isEnabled)?_c('form',{staticClass:"back-in-stock-form",on:{"submit":_vm.onSubmit}},[_c('base-input',{staticClass:"input",attrs:{"type":"email","name":"email","placeholder":_vm.$t('Email *'),"validations":[
        {
          condition: !_vm.$v.email.required && _vm.$v.email.$error,
          text: _vm.$t('Field is required.')
        },
        {
          condition: !_vm.$v.email.email && _vm.$v.email.$error,
          text: _vm.$t('Please provide valid e-mail address.')
        }
      ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('button-with-icon',{staticClass:"mt-4",attrs:{"type":"submit","color":"primary","disabled":_vm.$v.$invalid},nativeOn:{"click":function($event){return _vm.$v.email.$touch($event)}}},[_c('span',[_vm._v("\n      "+_vm._s(_vm.$t('Notify me when available'))+"\n    ")])])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }